/* MTUS Project CSS
 * version 1.3
 *  switched to common extends 1.1 with new font faces
 * version 1.2
 *  changed min-height
 * version 1.1
 *  Updated to work with isrdi-common.1.4
 *  Dropping bundle CSS
 */

$color_project_primary: #dbb216;
$color_project_secondary: #f8f0d0;
$color_project_dark: #c8a10e;

@import 'common-isrdi-variables.1.0';
@import 'base-ipums-variables.1.0';
@import 'common-isrdi-extends.1.1';

$color_link: $color_project_dark;
$color_link_ha: mix($color_project_primary,$color_near_white);

// ***
// * BEGIN Visual Styles for Page Structural Elements
// ***
nav[id="user_bar"] {
  color: $color_project_secondary;
}
nav[id="multi_project_menu"] {
  color:$color_project_primary; 
  a {
    &.current {
      background-color:$color_project_primary;
    }
  }
  ul {
    color:rgba($color_project_primary, .5);
  }
}
nav[id="topNav"] {
// navigation appearing at top right of page
  border-top-color:$color_project_secondary;

  .list-menu li a:hover {
    color:$color_link;
  }
}
header {
  background-image: url("//assets.ipums.org/_img/bkg-Number-Bar-Time-Use.png");
}
div[id="picturebar"] {
// decorative pictures in the header 
  div {
    background-color:$color_project_secondary;

    &:nth-child(1) { background-image: url("//assets.ipums.org/_img/mtus-top-img-1.jpg"); }
    &:nth-child(2) { background-image: url("//assets.ipums.org/_img/mtus-top-img-2.jpg"); }
    &:nth-child(3) { background-image: url("//assets.ipums.org/_img/mtus-top-img-3.jpg"); }
    &:nth-child(4) { background-image: url("//assets.ipums.org/_img/mtus-top-img-4.jpg"); }
    &:nth-child(5) { background-image: url("//assets.ipums.org/_img/mtus-top-img-5.jpg"); }
    &:nth-child(6) { background-image: url("//assets.ipums.org/_img/mtus-top-img-6.jpg"); }
  }
  &::after {
    background: linear-gradient(
      $color_primary,
      $color_primary $size_picturebar_border,
      $color_project_primary $size_picturebar_border,
      $color_project_primary 2*$size_picturebar_border);
  }
}
nav[id="sidebar-left"] {
  li {
    &.active {
      background-color:$color_project_primary;
    }
    a:hover { 
      color: $color_project_primary;
    }
    h3 {
      color:$color_project_dark;
    }
  }
}
// make sure menu doesn't get cut off
main, div[id="main"] {
  min-height:50rem;
}
footer {
  .list-menu {
    li {
      a:hover, a:active {
        color:$color_project_primary;
      }
    }
  }
  p {
    a:hover, a:active { 
      color:$color_project_primary;
    }
  }
}
// ***
// * END Visual Styles for Page Structural Elements
// ***

// ***
// * BEGIN General Tag Styling
// ***
a {
  &:link {
    color: $color_link;
  }
  &:hover {
    color: $color_link_ha;
  }
}
th {
  a {
    &:link {
      color: darken($color_link,5%);
    }
    &:hover {
      color: $color_link_ha;
    }
  }
}
table:not(.no-hover) tr:hover td {
  background-color:transparentize($color_project_primary,.85);
}
hr {
// is this ever used?
  background-color: $color_project_primary;
}
// ***
// * END General Tag Styling
// ***

// ***
// * BEGIN Specialized Classes
// ***
table.striping:not(.no-hover)  tr:nth-child(2n):hover td {
  background-color:transparentize($color_project_primary,.85);
}
.buttonbox {
  /* override with project colors */
  &,
  &:nth-child(3).buttonbox-primary {
    background-color:$color_project_dark;
    a {
      margin-left:0.5rem;
      margin-right:0.5rem;
      &:link, &:visited {
        color: $color_project_dark;
        background-color: $color_near_white;
      }
      &:hover {
        color:$color_project_primary;
        background-color:$color_project_secondary;
      }
      &:active {
        background-color:$color_near_white;
      }
    }
    h3 {
      color:$color_near_white;
      &:before, &:after  {
        border-color:$color_near_white;
      }
    }
  }
  &:nth-child(3),
  &.buttonbox-secondary {
    background-color:$color_project_secondary;
    a {
      &:link, &:visited {
        background-color: $color_project_primary;
      }
      &:hover {
        color:$color_project_dark;
        background-color:$color_near_white;
      }
      &:active {
        border-color:$color_project_primary;
      }
    }
    h3 {
      color:$color_project_dark;
      &:before, &:after  {
        border-color:$color_project_dark;
      }
    }
  }
}
